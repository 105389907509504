import React from 'react';
import PropTypes from 'prop-types';
import ExcelReportDownloadCommon from './common';


const ExcelPdfReport = ({data,fileName, header, title} : any) => {
  return (
    <ExcelReportDownloadCommon
      data={data}
      fileName={fileName}
      Header={header}
      buttonTitle={title}
    />
  );
};

ExcelPdfReport.propTypes = {
  data: PropTypes.any,
  fileName: PropTypes.string,
  header: PropTypes.any,
  title: PropTypes.string,
};

export default ExcelPdfReport;
