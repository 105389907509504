import Typography from '@mui/material/Typography';
import {
  Box,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchStateAndDivisionCreator,
  inventoryModulePRActions,
} from '@app/screens/inventory-management/pages/PendencyReport/redux/slice';
import {inventoryPRSelector} from '@app/screens/inventory-management/pages/PendencyReport/redux/selectors';
import SearchOption from '@app/screens/inventory-management/pages/PendencyReport/components/SearchOption';
import ExcelPdfReport from '@app/components/ExportAsExcel';

const styles = {
  containerCNF: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainerCNF: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControlCNF: {
    margin: '8px',
    width: '25%',
  },
  iconCNF: {
    marginTop: '4px',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 1,
  },
  formControl: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    marginRight: 9,
    left: 7,
  },
  formControlSearch: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    marginRight: 9,
    left: 47,
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface STATE {
  name: string;
  id: number;
  stateName: string;
}

interface DIVISION {
  name: string;
  shortName: string;
  id: number;
}

interface STATE_DIVISION_PAYLOAD {
  stateData: STATE[];
  divisionData: DIVISION[];
}

interface HEADQUARTER {
  hqNames: string;
  hqId: number;
}

interface State {
  stateDivisionLoader: boolean;
  allStates: STATE[];
  allDivisions: DIVISION[];
  headquaterLoader: boolean;
  allHeadquaters: HEADQUARTER[];
  selectedState: STATE[];
  selectedDivision: DIVISION[];
  selectedHeadquater: HEADQUARTER[];
  pageNumber: number;
}

interface HEADQUARTER_PAYLOAD {
  divisionId: number[];
}
interface HEADQUARTER_DATA_PAYLOAD {
  stateId: number[];
  divisionId: number[];
}

const columnHeaders = [
  {id: 'SAP code', name: 'sapCode', title: 'SAP code'},
  {id: 'Reporting To', name: 'reportingTo', title: 'Reporting To'},
  {id: 'SAP code', name: 'itemCode', title: 'Item/Sample Code'},
  {id: 'SAP code', name: 'itemName', title: 'Item/Sample Name'},
  {id: 'SAP code', name: 'pack', title: 'Pack'},
  {id: 'SAP code', name: 'quantity', title: 'Total Qty'},
  {
    id: 'SAP code',
    name: 'pendingSince30Days',
    title: 'Pending Since More Than 30 Days',
  },
  {
    id: 'SAP code',
    name: 'pendingSince45Days',
    title: 'Pending Since More Than 45 Days',
  },
];

export const PendencyReportNavbar = () => {
  const dispatch = useDispatch();
  const divisions = useSelector(inventoryPRSelector.getSelectedDivision());
  const allDivisions = useSelector(inventoryPRSelector.getAllDivisions());

  const pendencyReportData = useSelector(
    inventoryPRSelector.getPendencyReportData(),
  );

  useEffect(() => {
    dispatch(fetchStateAndDivisionCreator());
  }, []);

  const handleDivisionChange = (e: any) => {
    const divID: any =
      e.target.value === 0
        ? allDivisions?.map((item: any) => item.id)
        : [e.target.value];

    dispatch(inventoryModulePRActions.setSelectedDivision(divID));
  };

  return (
    <Box sx={styles.containerCNF}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Typography
          data-testid={'Pendency Report'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          Pendency Report
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignContent="center"
          marginRight={1.7}>
          <ExcelPdfReport
            data={pendencyReportData?.adminPendencyReportDtos}
            fileName={'Pendency Report'}
            header={columnHeaders}
            title={'Download Report'}
          />
        </Stack>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <FormControl sx={styles.formControlCNF}>
          <InputLabel id="select-division-label">
            Select Division <span style={{color: 'red', fontSize: 20}}>*</span>
          </InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            onChange={handleDivisionChange}
            MenuProps={MenuProps}
            value={divisions}>
            {allDivisions &&
              allDivisions != undefined &&
              allDivisions.length > 0 &&
              allDivisions?.map((divisionData: DIVISION) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.id}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <SearchOption />
      </Box>
    </Box>
  );
};
