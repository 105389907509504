import jsPDF from 'jspdf';
import jsAutoTable, { RowInput } from 'jspdf-autotable';

const exportDataToPDF = async (
  pdfHeader: any,
  pdfColumnName: any,
  data: any,
  fileName: any,
) => {
  const doc = new jsPDF('l', 'mm', 'a4');
  const pdfData :RowInput[] = data.map((rowData: any) => {
        return pdfColumnName.map((item: any) => rowData[item.name])
    });

  const marginHeight = 20;

  const options = {
    margin: {top: marginHeight, left: 10},
    beforePageContent: () => {
      doc.text(pdfHeader, 80, 12);
      doc.setFontSize(19);
    },
    createdRow: (row: { styles: { lineColor: string; }; }) => {
      row.styles.lineColor = 'black';
    },
    drawCell: () => {
      doc.setFontSize(4);
    },
    startY: marginHeight,
  };

  const columnStyles = getColumnStyles({
    pdfColumnName,
    doc,
    pdfData,
  });


  jsAutoTable(doc, {
    head: [pdfColumnName],
    body: pdfData,
    theme: 'grid',
    headStyles: {
      lineWidth: 0.2,
      lineColor: 'black',
      textColor: 'black',
      fillColor: '#ffe699',
      fontSize: 7.5,
      fontStyle: 'normal',
    //   fontWeight: 'bold',
      halign: 'center',
    },
    styles: {
      fontSize: 7,
      halign: 'center',
      textColor: 'black',
      lineColor: 'black',
    },
    columnStyles,
    ...options,
  });

  doc.save(`${fileName}.pdf`);
};

const getColumnStyles = ({pdfColumnName, doc, pdfData}: any) => {
  const columnWidths = pdfColumnName.map((_: any, colIndex: string | number) => {
    const maxContentWidth = Math.max(
      doc.getStringUnitWidth(pdfColumnName[colIndex]) * 1.6,
      ...pdfData.map((row: { [x: string]: any; }) => {
        const cellContent = String(row[colIndex]);
        return doc.getStringUnitWidth(cellContent) * 1.5;
      }),
    );
    return maxContentWidth;
  });

  return columnWidths.reduce((acc: { [x: string]: { cellWidth: number; }; }, width: any, colIndex: number) => {
    acc[colIndex] = {cellWidth: [0, 1].includes(colIndex) ? 40 : 25};
    return acc;
  }, {});
};

export default exportDataToPDF;
