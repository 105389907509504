import {Search} from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Autocomplete,
} from '@mui/material';
import React, {ChangeEvent, useEffect} from 'react';
import {debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {inventoryPRSelector} from '../redux/selectors';
import {
  fetchSubordinateCreator,
  fetchUserListDataCreator,
  inventoryModulePRActions,
} from '../redux/slice';
interface UserList {
  userId: string;
  roleName: any;
  searchData: any;
  displayData: any;
}
const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 1,
  },
  formControl: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
  },
};

export enum SearchType {
  userName = 'UserName',
  staffCode = 'StaffCode',
}
const searchTypeLabel = {
  UserName: 'User Name',
  StaffCode: 'SAP Code',
};

export const utility: any = {
  handleDDChange: null,
  handleTextChange: null,
  debouncedCallbackChange: null,
};

const SearchOption = () => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = React.useState<string>('');
  const [searchText, setSearchText] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] =
    React.useState<UserList | null>(null);
  const adminData = useSelector(inventoryPRSelector.getAdminData());
  const divisions = useSelector(inventoryPRSelector.getSelectedDivision());

  const usersAutosuggest = useSelector(
    inventoryPRSelector.getUserListData(),
  );
  adminData?.divisionIds?.divisionId[0];
  const isActiveUsers = useSelector(inventoryPRSelector.getActiveUsers());
  const loading = open && usersAutosuggest.length === 0;
  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue(null);
    setSearchText('' as string);
    setSearchType(e?.target?.value as SearchType);
  };
  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText('');
    setSearchText(e?.target?.value);
    if (e?.target?.value.length >= 3) {
      const userListData = {
        SearchValue: e?.target?.value,
        IsActive: isActiveUsers,
      };
      dispatch(fetchUserListDataCreator(userListData));
    }
  };

  useEffect(() => {
    setAutocompleteValue(null);
    setSearchType('');
    setSearchText('' as string);

  }, [divisions]);
  useEffect(() => {
    if (autocompleteValue && autocompleteValue.roleName !== 'MR') {
      dispatch(fetchSubordinateCreator({userIds: autocompleteValue?.userId}));
    } else {
      dispatch(inventoryModulePRActions.setSubordinates({}));
    }
    dispatch(
      inventoryModulePRActions.setSelectedStaff(autocompleteValue?.userId),
    );
  }, [autocompleteValue]);

  function sleep(duration: number): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    return () => {
      active = false;
    };
  }, [loading]);

  const debouncedCallbackChange = debounce(handleTextChange, 1200);
  utility.handleDDChange = handleDDChange;
  utility.handleTextChange = handleTextChange;
  utility.debouncedCallbackChange = debouncedCallbackChange;
  return (
    <Box sx={styles.formContainer}>
      <FormControl sx={styles.formControl} style={{marginRight: '12px'}}>
        <InputLabel id="search-type-select-label">Search By</InputLabel>
        <Select
          inputProps={{
            'data-testid': 'dropdown-search-type',
          }}
          variant="outlined"
          labelId="search-type-select-label"
          label="Search By"
          sx={{width: 250}}
          value={searchType}
          onChange={handleDDChange}>
          <MenuItem value={SearchType.userName}>
            {searchTypeLabel[SearchType.userName]}
          </MenuItem>
          <MenuItem value={SearchType.staffCode}>
            {searchTypeLabel[SearchType.staffCode]}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={styles.formControl}>
        <Autocomplete
          data-testid="search-type"
          options={usersAutosuggest}
          getOptionLabel={option => option.displayData}
          onChange={(_event, newValue) => {
            if (newValue) {
              setAutocompleteValue(newValue);
              setSearchText(newValue.displayData);
            } else {
              setAutocompleteValue(null);
            }
          }}
          value={autocompleteValue}
          open={open}
          loading={loading}
          onOpen={() => {
            if (searchType && searchText) {
              setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          renderInput={params => (
            <TextField
              {...params}
              disabled={!searchType} // Disable when no search type or no text and dropdown closed
              data-testid="search-texfield"
              placeholder="Search By Name/SAP Code"
              onChange={debouncedCallbackChange}
              sx={{width: 419}}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default SearchOption;
