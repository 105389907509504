import {createAction, createSlice} from '@reduxjs/toolkit';

export const filterInitialState = {
  filters: {},
  filterApplied: false,
  filterAppliedDAMaster: false,
  filterLoading: false,
  filterError: null,
  filterErrorMessage: '',
  setDefaultFilterResponse: null,
  applyDisableStatus: true,
  filterOptions: {
    isActive: true,
    searchText: '',
    pageNumber: 0,
    pageLimit: 100,
    divisionIds: [],
    designationIds: [],
    hqIds: [],
  },
  filterBtn: true,
  notificationData: [],
};

export const applyFilterCreator = createAction<any>(
  'FILTER_STATE/APPLY_FILTER',
);
export const applyFilterCreatorTypeName = applyFilterCreator({}).type;

export const setDefaultFilterCreator = createAction<any>(
  'FILTER_STATE/SET_DEFAULT_FILTER',
);
export const setDefaultFilterCreatorTypeName = setDefaultFilterCreator({}).type;

export const resetDefaultFilterCreator = createAction<any>(
  'FILTER_STATE/RESET_DEFAULT_FILTER',
);
export const resetDefaultFilterCreatorTypeName = resetDefaultFilterCreator(
  {},
).type;

const filterStateHandler = createSlice({
  name: 'FILTER_HANDLER',
  initialState: filterInitialState,
  reducers: {
    setFilterState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filters: payload,
      };
    },
    setFilterStateIndividual: (prevState: any, action) => {
      const {payload} = action;

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          [payload.key]: payload.options,
        },
      };
    },
    setFilterCollapsedState: (prevState: any, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          [payload.key]: {
            ...prevState.filters[payload.key],
            collapsed: payload.value,
          },
        },
      };
    },
    setFilterAppliedDAMaster: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filterAppliedDAMaster: payload,
      };
    },
    setFilterApplied: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filterApplied: payload,
      };
    },
    setFilterLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filterLoading: payload,
      };
    },
    setDefaultFilterResponse: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        setDefaultFilterResponse: payload,
      };
    },
    setApplyDisableStatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        applyDisableStatus: payload,
      };
    },
    setFilterOptions: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filterOptions: payload,
      };
    },
    setFilterBtn: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filterBtn: payload,
      };
    },
    setNotification: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        notificationData:payload,
      }
    }
  },
});

export const filterStateActions = filterStateHandler.actions;
export const filterStateReducer = filterStateHandler.reducer;
