import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {LedgerReportstyles as styles} from './styles';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {nanoid} from '@reduxjs/toolkit';
import {Search} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchAutoSuggestListForStaffNameCreator,
  inventoryModuleLedgerActions,
} from '@app/screens/inventory-management/pages/ledgerReport/redux/slice';
import {inventoryLedgerSelector} from '@app/screens/inventory-management/pages/ledgerReport/redux/selectors';
import exportDataToPDF from '@app/components/ExportAsPDF';
import { data } from '@app/screens/inventory-management/pages/ledgerReport';
import { debounce } from 'lodash';

export enum SearchType {
  items = 'Items',
  sample = 'Samples',
}
const searchTypeLabel = {
  Items: 'Items',
  Samples: 'Samples',
};

const columnHeaders = [
  {'id': 1, 'name': 'transactionDetails', 'title': 'Transaction Deatils'},
  {'id': 2, 'name': 'sampleItemName', 'title': 'Sample/Item Name'},
  {'id': 3, 'name': 'openingBalance', 'title': 'Total Balance'},
  {'id': 4, 'name': 'voucherDate', 'title': 'Transaction Date'},
  {'id': 5, 'name': 'receive', 'title': 'Item Receive'},
  {'id': 6, 'name': 'transfer', 'title': 'Tansfered Item'},
  {'id': 7, 'name': 'shortQty', 'title': 'Short Qty'},
  {'id': 8, 'name': 'distributedQty', 'title': 'Used Qty'},
  {'id': 9, 'name': 'confirmDistributed', 'title': 'Confirm Distribution Qty'},
  {'id': 10, 'name': 'balance', 'title': 'Balance'},
];
export const LedgerReportNavbar = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openSearch1, setOpenSearch1] = useState(false);
  const [openSearch2, setOpenSearch2] = useState(false);
  const [searchType, setSearchType] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [autoCompleteValueForStaff, setAutoCompleteValueForStaff] = useState<
    any[]
  >([]);
  const dispatch = useDispatch();

  const getAutoSuggestForStaffName = useSelector(
    inventoryLedgerSelector.getAutoSuggestListForStaff(),
  );
  const handleEffectiveDate = (date: any) => {
    setStartDate(date);
  };

  useEffect(() => {
    let autoSuggest: any[] = [];
    if (getAutoSuggestForStaffName.length > 0) {
      getAutoSuggestForStaffName.forEach((element: any) => {
        if (!autoSuggest.includes(element.displayData)) {
          autoSuggest.push(element.displayData);
        }
      });
    }
    setAutoCompleteValueForStaff(autoSuggest);
  }, [getAutoSuggestForStaffName]);

  const handleValidDate = (date: any) => {
    setEndDate(date);
  };

  const onTextFieldChange = (e: any) => {
    debouncedAutoSuggest(e.target.value);
  };

  const handleDDChange = (e: any) => {
    setSearchType(e.target.value);
  };

  const debouncedAutoSuggest = debounce((newval: string) => {
    
    if (newval.length >= 3) {
      dispatch(
        fetchAutoSuggestListForStaffNameCreator({
          searchText: newval || '',
          isActive: true,
        }),
      );
    }
  }, 1000);
  
  const debouncedCallbackChange = () => {};

  const genratePDF = () => {
    exportDataToPDF('', columnHeaders, data, 'Ledger Reports Download');
  }

  return (
    <Box sx={styles.containerCNF}>
      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
        }}>
        {' '}
        <Typography
          data-testid={'Pendency Report'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          Ledger Report
        </Typography>
      <Box>
        <Button
          variant='contained'
          onClick={() => genratePDF()} 
        >
          Download as pdf
          </Button>
        </Box>
      </Box>
      <Box
        display={'inline-flex'}
        flexDirection={'row'}
        // justifyContent={'space-between'}
        paddingTop={3}>
        <Box display={'inline-flex'} flex={'auto'} flexDirection={'column'}>
          <div>
            Search By <span style={{color: 'red'}}>*</span>
          </div>

          <Box sx={styles.container2}>
            <FormControl sx={styles.formControl} fullWidth>
              <Autocomplete
                data-testid="searchText"
                key={'key'}
                open={openSearch1}
                sx={{width: '100%'}}
                onChange={(event, newval) => {

                  if (newval) {
                    debouncedAutoSuggest(newval);
                  } else {
                    dispatch(
                      inventoryModuleLedgerActions.setAutoSuggestListofStaff(
                        [],
                      ),
                    );
                  }
                }}
                onOpen={() => {
                  setOpenSearch1(true);
                }}
                onClose={() => {
                  setOpenSearch1(false);
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option === value;
                }}
                options={autoCompleteValueForStaff || []}
                renderInput={(params: any) => (
                  <TextField
                    data-testid="test-texfield"
                    onChange={onTextFieldChange}
                    {...params}
                    placeholder={'Search by SAP Code/Name'}
                    sx={styles.textField}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" sx={styles.padding5}>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Box>
        <div>
          <div>
            Start Date<span style={{color: 'red'}}>*</span>
          </div>
          <Box sx={styles.container2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="YYYY/MM/DD"
                key={nanoid()}
                onChange={handleEffectiveDate}
                maxDate={dayjs().format('YYYY/MM/DD')}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="startDate"
                      {...params}
                      sx={[styles.startDateStyle, styles.paddingForMUIInput]}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div>
          <div>
            End Date<span style={{color: 'red'}}>*</span>
          </div>
          <Box sx={styles.container2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={endDate}
                onChange={handleValidDate}
                inputFormat="YYYY/MM/DD"
                key={nanoid()}
                disabled={startDate === null}
                maxDate={dayjs().format('YYYY/MM/DD')}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="endDate"
                      sx={[styles.endDateStyle, styles.paddingForMUIInput]}
                      {...params}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
      </Box>
      <Box
        sx={styles.formContainer}
        display={'inline-flex'}
        flexDirection={'row'}>
        <div>
          <div>Item Type</div>
          <Box sx={styles.container}>
            <FormControl sx={styles.formControl} fullWidth>
              <Select
                inputProps={{
                  'data-testid': 'dropdown-search-type',
                }}
                variant="outlined"
                labelId="search-type-select-label"
                sx={[styles.itemTypeStyle, styles.paddingForMUIInput]}
                value={searchType}
                onChange={handleDDChange}>
                <MenuItem value={SearchType.items}>
                  {searchTypeLabel[SearchType.items]}
                </MenuItem>
                <MenuItem value={SearchType.sample}>
                  {searchTypeLabel[SearchType.sample]}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={styles.marginForSearch}>
          <div>Search By</div>
          <Box sx={styles.container}>
            <FormControl sx={styles.formControl} fullWidth>
              <Autocomplete
                options={[]}
                getOptionLabel={(option: any) => {
                  if (searchType === SearchType.sample) {
                    return option.userName;
                  } else if (searchType === SearchType.items) {
                    return option.staffCode;
                  }
                  return '';
                }}
                onChange={(event, newValue) => {
                  let searchTextValue = newValue;
                  setAutocompleteValue(newValue);
                  setSearchText(searchTextValue);
                }}
                value={autocompleteValue}
                open={openSearch2}
                onOpen={() => {
                  if (searchType && searchText) {
                    setOpenSearch2(true);
                  }
                }}
                onClose={() => setOpenSearch2(false)}
                renderInput={params => (
                  <TextField
                    {...params}
                    disabled={!searchType} // Disable when no search type or no text and dropdown closed
                    data-testid="search-texfield"
                    placeholder="Search By Sample Code / Sample Name"
                    onChange={debouncedCallbackChange}
                    sx={styles.textField2}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <Button
              variant="contained"
              size="small"
              onClick={() => {}}
              sx={{textTransform: 'none', padding: 0, marginLeft: 2}}>
              GO
            </Button>
          </Box>
        </div>
        <div>
          <div></div>
        </div>
      </Box>
      {/* </Box> */}
    </Box>
  );
};
