import {useEffect, useState} from 'react';
import {CustomModal} from '../widgets/CustomModal';
import {Box} from '@mui/system';
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {YEAR_DATE_FORMAT} from '@app/common/constants';
import {getFormatDate} from '@app/utils/dateTimeHelper';
import FilePreviewModal from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/FilePreviewModal';
import {Search} from '@mui/icons-material';
import {navbarStyles} from '../widgets/AntSwitch/AntSwitch';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {
  challanShortQtyAuditTrailDataCreator,
  challanShortQtyAuditTrailDownloadCreator,
  challanShortQtyAutoSearchCreator,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/slice';
import {challanShortQtyApprovalSelector} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/selectors';
import {GeneralReportTableStyles as styles} from './style';

export const util: any = {
  onTextFieldChange: null,
  handleDDChange: null,
  debouncedSearch: null,
};

export enum SearchType {
  userName = 'staffname',
  staffCode = 'staffcode',
  voucherNumber = 'challannumber',
  hqname = 'hqname',
}
const searchTypeLabel = {
  challannumber: 'Voucher Number',
  staffname: 'Employee Name',
  staffcode: 'SAP Code',
  hqname: 'HQ',
};

const GeneralReportTable = ({
  onCloseTable,
  tableModalopen,
  innerModalValue = false,
  search = '',
  searchtype = '',
}: any) => {
  const [reportChallanData, setReportChallanData] = useState<any>([]);
  const [innerModalOpen, setInnerModalOpen] = useState(innerModalValue);
  const [fileNameToPreview, setFileNameToPreview] = useState('');
  const [searchText, setSearchText] = useState<string>(search);
  const [autocompleteValue, setAutocompleteValue] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [searchType, setSearchType] = useState<string>(searchtype);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 20;
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const challanShortQtyAuditTrailData = useSelector(
    challanShortQtyApprovalSelector.getChallanShortQtyAuditTrailData(),
  );
  const challanAuditTrailPayload = useSelector(
    challanShortQtyApprovalSelector.getChallanShortQtyAuditTrailPayload(),
  );

  const AutoSuggestion = useSelector(
    challanShortQtyApprovalSelector.getChallanAuditTrailAutoSearch(),
  );

  const Loader = useSelector(challanShortQtyApprovalSelector.getChallanAuditTrailReportLoader());

  useEffect(() => {
    if (challanShortQtyAuditTrailData?.adminShortQuantityAuditTrailResponse) {
      setReportChallanData(
        challanShortQtyAuditTrailData?.adminShortQuantityAuditTrailResponse,
      );
    }
    if (challanShortQtyAuditTrailData?.totalRecords) {
      setTotalRecords(parseInt(challanShortQtyAuditTrailData.totalRecords));
    }
  }, [challanShortQtyAuditTrailData]);

  const handleFilePreviewModalOpen = (fileName: string) => {
    setInnerModalOpen(true);
    setFileNameToPreview(fileName);
  };
  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchText(newSearchTerm);
  };
  const handleClose = () => {
    onCloseTable();
    setReportChallanData([]);
  }
  const handleDownload = () => {
    const params = {
      filterPayload: challanAuditTrailPayload,
      filterValue: searchText,
      returnInBase64: false,
    };
    dispatch(challanShortQtyAuditTrailDownloadCreator(params));
  };

  const debouncedSearch = _.debounce(() => {
    const autosuggest = {
      pageNumber: pageNumber,
      filterPayload: challanAuditTrailPayload,
      filterValue: searchText,
      searchType: searchType,
    };
    dispatch(challanShortQtyAutoSearchCreator(autosuggest));
  }, 1000);

  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue('');
    setSearchText('' as string);
    setSearchType(e.target.value as SearchType);
  };

  util.handleDDChange = handleDDChange;
  util.debouncedSearch = debouncedSearch;
  util.onTextFieldChange = onTextFieldChange;

  useEffect(() => {
    if (searchText !== '' && searchType !== '') {
      debouncedSearch();
    } else {
      dispatch(
        challanShortQtyAuditTrailDataCreator({
          ...challanAuditTrailPayload,
          pageNumber: pageNumber,
        }),
      );
    }
    setReportChallanData([]);
    return () => debouncedSearch.cancel();
  }, [searchText, pageNumber, searchType]);

  const searchFunction = () => {
    return (
      <Box sx={styles.QtyformContainer}>
        <Box display={'flex'} flexDirection={'row'}>
          <FormControl sx={styles.QtyformControl} fullWidth>
            <InputLabel id="search-type-select-label">Search By</InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-search-type',
              }}
              variant="outlined"
              labelId="search-type-select-label"
              label="Search By"
              sx={{width: '100%'}}
              value={searchType}
              onChange={handleDDChange}>
              <MenuItem value={SearchType.voucherNumber}>
                {searchTypeLabel[SearchType.voucherNumber]}
              </MenuItem>
              <MenuItem value={SearchType.staffCode}>
                {searchTypeLabel[SearchType.staffCode]}
              </MenuItem>
              <MenuItem value={SearchType.userName}>
                {searchTypeLabel[SearchType.userName]}
              </MenuItem>
              <MenuItem value={SearchType.hqname}>
                {searchTypeLabel[SearchType.hqname]}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={styles.serachFormControl}>
            <Autocomplete
              data-testid="Searchfield"
              noOptionsText={''}
              freeSolo={true}
              key={'key'}
              open={open}
              sx={{width: '100%'}}
              disabled={!searchType}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onInputChange={(event, newValue) => {
                setAutocompleteValue(newValue);
                setSearchText(newValue);
              }}
              value={autocompleteValue}
              renderInput={(params: any) => (
                <TextField
                  data-testid="test-texfield"
                  onChange={onTextFieldChange}
                  {...params}
                  placeholder={'Search by SAP Code, Name, Challan Number'}
                  sx={styles.searchbar}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={navbarStyles.padding5}>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              options={AutoSuggestion || []}
            />
          </FormControl>
          <Box>
            <Button
              data-testid="Download-button"
              variant="contained"
              fullWidth
              sx={{textTransform: 'none'}}
              disabled={reportChallanData?.length === 0}
              style={styles.reports}
              onClick={() => {
                handleDownload();
              }}>
              Download
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <Box>
        {totalRecords > 0 && (
          <Pagination
            data-testid="pagination"
            count={calculatePage()} // Total number of pages
            page={pageNumber} // Current page
            onChange={handlePageChange} // Event handler for page change
            variant="outlined"
            shape="rounded"
          />
        )}
      </Box>
    );
  };
  const calculatePage = () => {
    return Math.ceil(totalRecords / pageSize);
  };
  const handleFilePreviewModalClose = () => {
    setInnerModalOpen(false);
    setFileNameToPreview('');
  };
  const handlePageChange = (event: any, page: any) => {
    setPageNumber(page);
  };
  return (
    <CustomModal
      search={searchFunction()}
      maxWidth={'xl'}
      open={tableModalopen}
      searchClass={styles.search}
      footer={getFooter()}
      onClose={handleClose}
      footerClass={styles.footer}
      fullWidth={true}
      backdropClick={true}>
      {reportChallanData.length === 0 && Loader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}>
          <CircularProgress size={40} />
        </div>
      )}
      {reportChallanData.length > 0 && (
        <TableContainer sx={styles.Table}>
          <Table stickyHeader>
            <TableHead>
              <TableCell>
                <Typography sx={styles.container}>Voucher Number</Typography>
              </TableCell>
              <TableCell>
                <Box>
                  <Typography sx={styles.container}>SAP Code</Typography>
                  <Typography sx={styles.container}>Name</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>HQ</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>Voucher Date</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={styles.container}>Item Code</Typography>
                <Typography sx={styles.container}>Item Name</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>Original Qty</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>
                  Received Short Qty
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>GR Number</Typography>
                <Typography sx={styles.container}>GR Date</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={styles.container}>Transporter Name</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.container}>Approved By </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.container}>
                  Ref. Doc
                </Typography>
              </TableCell>
            </TableHead>
            {!Loader && reportChallanData?.length > 0 &&
              reportChallanData?.map((data: any) => {
                const {
                  voucherNumber,
                  staffName,
                  staffCode,
                  voucherDate,
                  grNumber,
                  grDate,
                  modeOfTransport,
                  hqName,
                  referenceDocument,
                  approvedBy,
                  itemCode,
                  itemName,
                  originalQty,
                  receivedQty,
                } = data;

                const viewButton = referenceDocument ? (
                  <Button
                    key={voucherNumber}
                    data-testid="view-button"
                    variant="contained"
                    sx={{textTransform: 'none'}}
                    style={styles.eyeButton}
                    onClick={() => {
                      handleFilePreviewModalOpen(referenceDocument);
                    }}>
                    View
                  </Button>
                ) : null;
                return (
                  <TableRow key={voucherNumber}>
                    <TableCell sx={styles.ChallanTableCell}>
                      {voucherNumber}
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      <div>
                        <div>{staffCode}</div>
                        <div>{staffName}</div>
                      </div>
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>{hqName}</TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      {getFormatDate({
                        date: voucherDate,
                        format: YEAR_DATE_FORMAT,
                      })}
                    </TableCell>

                    <TableCell sx={styles.ChallanTableCell}>
                      <div>
                        <div>{itemCode}</div>
                        <div>{itemName}</div>
                      </div>
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      {originalQty}
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      {receivedQty}
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      <div>{grNumber}</div>
                      <div>
                        {getFormatDate({
                          date: grDate,
                          format: YEAR_DATE_FORMAT,
                        })}
                      </div>
                    </TableCell>

                    <TableCell sx={styles.ChallanTableCell}>
                      {modeOfTransport}
                    </TableCell>
                    <TableCell sx={styles.ChallanTableCell}>
                      <Chip
                        size={'small'}
                        color="primary"
                        variant="filled"
                        label={approvedBy}
                      />
                    </TableCell>
                    <TableCell>{viewButton}</TableCell>
                  </TableRow>
                );
              })}
          </Table>
        </TableContainer>
      )}
      {!Loader && reportChallanData.length === 0 && (
        <Typography sx={{display: 'flex', justifyContent: 'center'}}>
          There are no records
        </Typography>
      )}
      {innerModalOpen && (
        <FilePreviewModal
          innerModalOpen={innerModalOpen}
          handleFilePreviewModalClose={handleFilePreviewModalClose}
          fileName={fileNameToPreview}
        />
      )}
    </CustomModal>
  );
};

export default GeneralReportTable;
