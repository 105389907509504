import {useEffect, useState} from 'react';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {Box} from '@mui/material';
import {useDispatch} from 'react-redux';
import {LedgerReportStyles as styles} from './styles';
import PaginatedTableforLedger from './components/PaginatedTableForLedger';

interface Data {
  id: number;
  transactionDetails: string;
  sampleItemName: string;
  voucherDate: string;
  receiveDate: string;
  openingBalance: number;
  shortQty: number;
  transfer: number;
  receive: number;
  dcrDate: string;
  distributedQty: number;
  confirmDistributed: number;
  balance: number;
}
export const data: Data[] = [
  {
    transactionDetails: '089796',
    sampleItemName: 'Caldikind 60k Sachet',
    voucherDate: '12-01-2024',
    receiveDate: '24-01-2024',
    openingBalance: 31,
    shortQty: 0,
    transfer: 4,
    receive: 0,
    dcrDate: '',
    distributedQty: 0,
    confirmDistributed: 0,
    balance: 50,
    id: 6690289,
  },
  {
    transactionDetails: 'Tansfer to user1 on 01.02.2024',
    sampleItemName: 'Caldikind 60k Sachet',
    voucherDate: '',
    receiveDate: '',
    openingBalance: 35,
    shortQty: 0,
    transfer: 4,
    receive: 0,
    dcrDate: '',
    distributedQty: 0,
    confirmDistributed: 0,
    balance: 31,
    id: 6690282,
  },
  {
    transactionDetails: 'Distribute to Dr. Amit',
    sampleItemName: 'Caldikind 60k Sachet',
    voucherDate: '',
    receiveDate: '',
    openingBalance: 40,
    shortQty: 0,
    transfer: 4,
    receive: 0,
    dcrDate: '05-02-2024',
    distributedQty: 5,
    confirmDistributed: 0,
    balance: 35,
    id: 669028,
  },
  {
    transactionDetails: 'Distribute to Dr. Ankur',
    sampleItemName: 'Lipikind DMF Badges- MKD',
    voucherDate: '',
    receiveDate: '',
    openingBalance: 100,
    shortQty: 0,
    transfer: 6,
    receive: 0,
    dcrDate: '16-03-2023',
    distributedQty: 2,
    confirmDistributed: 0,
    balance: 34,
    id: 6690222,
  },
  {
    transactionDetails: 'Tansfer to CNF',
    sampleItemName: 'Lipikind DMF Badges- MKD',
    voucherDate: '',
    receiveDate: '',
    openingBalance: 50,
    shortQty: 0,
    transfer: 4,
    receive: 0,
    dcrDate: '16-03-2023',
    distributedQty: 3,
    confirmDistributed: 0,
    balance: 26,
    id: 6690382,
  },
  {
    transactionDetails: 'Distributed to Dr. xyz',
    sampleItemName: 'Lipikind DMF Badges- MKD',
    voucherDate: '24-04-2023',
    receiveDate: '10-10-2023',
    openingBalance: 43,
    shortQty: 2,
    transfer: 6,
    receive: 0,
    dcrDate: '08-07-2023',
    distributedQty: 0,
    confirmDistributed: 0,
    balance: 16,
    id: 6690285,
  },
];
export const LedgerReport = () => {
  const dispatch = useDispatch();
  const [ledgerReportData, setLedgerReportData] = useState<Data[]>([]);

  useEffect(() => {
    setLedgerReportData(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.ledgerReport,
      ),
    );
    return () => {};
  }, []);
  const rowsPerPageOptions = [5, 10, 25];

  return (
    <Box sx={styles.container}>
      {ledgerReportData && ledgerReportData.length > 0 &&
        <PaginatedTableforLedger
          data={data}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      }

      {ledgerReportData?.length === 0 && <Box>No Data To Show.</Box>}
    </Box>
  );
};
