import React, {useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

const styles = {
  containerCNF: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainerCNF: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControlCNF: {
    margin: '8px',
  },
  iconCNF: {
    marginTop: '4px',
  },
  headRow: {
    backgroundColor: '#dde8ec',
    borderRadius: 5,
  },
  tableView: {
    borderRadius: 5,
    // overflowWrap: 'break-word',
  },
  tableHeadCol: {
    // fontVariantCaps: 'all-petite-caps',
    textTransform: 'uppercase',
    fontSize: 13,
    textAlign: 'center',
  },
  tableBodyCol:{
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    color: '#1c1939'
  },
  transferDetailsCol:{
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'break-word',
    color: '#1c1939'

  },
  paperView: {
    width: '-webkit-fill-available',
  },
  /* Track */
  '::-webkit-scrollbar' : {
    width: '12px', /* Width of the scrollbar */
  },

  '::-webkit-scrollbar-thumb:hover' : {
    backgroundColor: '#555' /* Color of the scrollbar handle on hover */

  }
};

interface Data {
  id: number;
  transactionDetails: string;
  sampleItemName: string;
  voucherDate: string;
  receiveDate: string;
  openingBalance: number;
  shortQty: number;
  transfer: number;
  receive: number;
  dcrDate: string;
  distributedQty: number;
  confirmDistributed: number;
  balance: number;
  //old
}

interface PaginatedTableProps {
  data: Data[];
  rowsPerPageOptions: number[];
}

const PaginatedTableforLedger: React.FC<PaginatedTableProps> = ({
  data,
  rowsPerPageOptions,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formateDate = (date : string) => {
    if (date !== '') {
      const parsedDate = dayjs(date, 'DD-MM-YYYY');    
      return parsedDate.format('DD-MMM-YYYY');
    } else {
      return '-';
    }
  }
  

  return (
    <Paper style={styles.paperView}>
      <TableContainer style={styles.tableView}>
        <Table>
          <TableHead style={styles.headRow}>
            <TableRow>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>
                  Transaction Deatils
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>
                  Sample/Item Name
                </Typography>
              </TableCell>
              
              <TableCell>
                <Typography sx={styles.tableHeadCol}>Total Balance</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>Transaction Date</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>Item Receive</Typography>
              </TableCell>
              
              <TableCell>
                <Typography sx={styles.tableHeadCol}>Tansfered Item</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>Short Qty</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>
                  Used Qty
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.tableHeadCol}>
                  Confirm Distribution Qty
                </Typography>
              </TableCell>
              
              <TableCell>
                <Typography sx={styles.tableHeadCol}>
                  Balance
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.transferDetailsCol}>
                      {' '}
                      {row.transactionDetails}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.sampleItemName}
                    </Typography>
                  </TableCell>
                  
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.openingBalance}
                    </Typography>
                  </TableCell>
                  
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {formateDate(row.voucherDate)}
                    </Typography>
                  </TableCell>
                  
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.receive}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.transfer}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.shortQty}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.distributedQty}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.confirmDistributed}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography sx={styles.tableBodyCol}>
                      {row.balance}
                    </Typography>
                  </TableCell>
                  
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PaginatedTableforLedger;
