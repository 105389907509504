import {Button} from '@mui/material';
import * as Excel from 'exceljs';

const ExcelReportDownloadCommon = ({
  data = [],
  fileName,
  Header,
  buttonTitle,
}: any) => {
  const fitToColumnExcelJs = (worksheet: any, data: any[]) => {
    let index = 1;
    for (const property in data[0]) {
      const maxColumnWidth = Math.max(
        property ? property.toString().length * 10 : 0,
        ...data.map(obj =>
          obj[property] ? obj[property].toString().length * 10 : 0,
        ),
      );
      if (index === 7) {
        worksheet.getColumn(index).width = 30;
      } else {
        worksheet.getColumn(index).width =
          maxColumnWidth < 110 ? 20 : maxColumnWidth / 7;
        index++;
      }
    }
  };

  const base64DownloaderMiscWeb = (excelBuffer: BlobPart) => {
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document?.createElement('a')
      ? document?.createElement('a')
      : {href: '', download: '', click: () => true};
    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}.csv`;
    downloadLink.click();
    return null;
  };

  const HeadersArr = Header.map((item: any) => item.title);
  const exportDataToExcel = async () => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('MTP');
    const excelData = data.map((rowData: any) => {
      return Header.map((item: any) => rowData[item.name]);
    });
    const finalExcelData = [
      [`Pendency Reports Download`, '', '', '', '', '', '', ''],
      [...HeadersArr],
      ...excelData,
    ];
    ws.addRows(finalExcelData);
    ws.mergeCells(1, 1, 1, 8);
    fitToColumnExcelJs(ws, excelData);
    wb.xlsx.writeBuffer().then(async (excelBuffer: any) => {
      base64DownloaderMiscWeb(excelBuffer);
    });
  };

  return (
    <Button
      data-testid={'Report-download'}
      variant="contained"
      size="small"
      onClick={exportDataToExcel}
      disabled={data?.length === 0}
      sx={{textTransform: 'none', height: 30, left: 5}}>
      {' '}
      {buttonTitle}
    </Button>
  );
};

export default ExcelReportDownloadCommon;
