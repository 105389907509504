export const LedgerReportStyles = {
    container: {
      width: '98%',
      margin: '16px auto',
      backgroundColor: 'white',
      paddingTop: '90px',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '32px',
    },
    textStyle: {
      fontFamily: 'Poppins',
      fontSize: 18.7,
      fontWeight: 'bold',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: '#1c1939',
    },
    erroMsg: {
      marginLeft: 3,
      marginTop: 2,
    },
    mrContainer: {
      fontFamily: 'Poppins',
      fontSize: 10.7,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.21,
      textAlign: 'left',
      color: '#494761',
      paddingLeft: 1.5,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    testContainer: {
      fontFamily: 'Poppins-Regular',
      fontSize: 10.7,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: '#494761',
      border: 0,
    },
  
    quantityReceivedClass: {
      width: 153.3,
      border: 0,
      paddingLeft: 0.2,
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 12.7,
    },
    remarksClass: {
      width: 153.3,
      border: 0,
      paddingLeft: 0.2,
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 12.7,
    },
    rowsClass: {
      boxShadow: 2,
      width: 500,
      height: 60,
      border: 0,
      borderRadius: 2,
      marginBottom: 2,
      display: 'grid',
      gridTemplateColumns: '1.5fr 1fr 1.8fr 1.5fr',
      padding: 0,
    },
    headsClass: {
      width: 509.3,
      border: 0,
      display: 'grid',
      gridTemplateColumns: '1.5fr 1fr 1.8fr 1.5fr',
    },
    classContainer: {
      height: 55,
      borderRadius: 2,
      marginBottom: 2,
    },
    classWidth: {
      width: 333.3,
      height: 55,
      borderRadius: 2,
      marginBottom: 2,
    },
    warningStyle: {
      position: 'relative',
      bottom: 26,
      left: 15,
      fontSize: 12,
      color: 'red',
    },
    itemSelected: {
      fontFamily: 'Poppins',
      fontSize: 10,
      fontWeight: '500',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: '#1c1939',
    },
    textField: {
      border: 'transparent',
      background: '#f2f2f2',
      borderRadius: '15px',
      width: '100%',
      padding: '0px',
      opacity: 0.6,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: 12,
    },
    padding5: {
      padding: '5px',
    },
    approveInputProp: {
      height: 55,
      borderRadius: 2,
      marginBottom: 2,
    },
    approveLabelStyle: {
      width: 88.7,
      height: 18,
      opacity: 0.4,
      fontFamily: 'Poppins-Regular',
      fontSize: 12.7,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: '#1c1939',
    },
    remarkLabel: {
      position: 'relative',
      bottom: 26,
      left: 15,
      fontSize: 12,
      color: 'red',
    },
    viewButton: {
      padding: '8px 22px',
      width: 100,
      height: 50,
      gap: '8px',
    },
    remarkCheck: {
      fontFamily: 'Poppins',
      fontSize: 10.7,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.21,
      textAlign: 'left',
      color: '#494761',
      paddingLeft: 1.5,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    rejectException: {
      paddingLeft: 10,
      fontFamily: 'Poppins',
      fontSize: 12.7,
      fontStyle: 'normal',
      letterSpacing: 0.21,
      textAlign: 'left',
      color: '#494761',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      fontWeight: 'bold',
    },
  };
  export const returnItemsTableStyle = {
    icon24Checkbox: {
      width: 24,
      height: 24,
    },
    container: {
      fontFamily: 'Poppins-Regular',
      fontSize: 12.7,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'left',
      color: '#494761',
    },
  };
  export const popUpStyle = {
    marginBottom15: {
      marginBottom: '15px',
    },
    width100: {
      width: '100%',
    },
    closeIcon: {
      color: '#1c1939',
      marginTop: -10.67,
      marginRight: -2.67,
    },
    dialogContent: {
      justifyContent: 'center',
      marginTop: 15,
    },
    twocolumnlayout: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    width90: {
      width: '90%',
    },
    textField: {
      border: 'transparent',
      borderRadius: 15,
      width: '100%',
      padding: '0px',
    },
    padding5: {
      padding: '5px',
    },
    labelSizing: {
      fontSize: '12px',
      color: '#1c1939',
      fontWeight: 800,
      marginBottom: '10px',
      marginLeft: '7px',
    },
    addcss: {
      color: '#1c1939',
      fontWeight: 800,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: '14px',
    },
    headingcss: {
      color: '#1c1939',
      fontWeight: 800,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: '18px',
    },
    resetButton: {
      backgroundColor: '#ffffff',
      color: '#322b7c',
      border: '2px solid #322b7c',
      borderRadius: '5px',
      width: '150px',
      boxShadow: 'none',
      marginRight: '10px',
      fontWeight: '600',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      textAlign: 'center',
      fontSize: 12.7,
      '&:hover': {
        background: 'none',
      },
    },
    saveButton: {
      backgroundColor: '#322b7c',
      borderRadius: '5px',
      width: '150px',
      boxShadow: 'none',
      marginLeft: '10px',
      fontWeight: '600',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      textAlign: 'center',
      fontSize: 12,
      '&:hover': {
        background: '#322b7c',
      },
    },
  };

