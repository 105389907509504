import { createAction, createSlice } from "@reduxjs/toolkit";

export const fetchAutoSuggestListForStaffNameCreator = createAction<any>(
  'FETCH_STATES_DIVISIONS',
);
export const fetchAutoSuggestListForStaffNameTypename =
fetchAutoSuggestListForStaffNameCreator({}).type;


const inventoryModuleLedgerHandler = createSlice({
    name: 'INVETORY_MODULE_LEDGER_REPORT',
    initialState: {
        autoSuggestListForStaffName: []
    },
    reducers: {
        setAutoSuggestListofStaff : (prevState,{payload}) => ({
            ...prevState,
            autoSuggestListForStaffName: payload,
        })
    }
});

export const inventoryModuleLedgerActions = inventoryModuleLedgerHandler.actions;
export const inventoryModuleLedgerReducer = inventoryModuleLedgerHandler.reducer;