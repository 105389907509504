import { call, put, takeLatest } from "redux-saga/effects";
import { fetchAutoSuggestListForStaffNameTypename, inventoryModuleLedgerActions } from "./slice";
import { API_PATH, HTTP_OK } from "@app/common/constants";
import { NetworkService } from "@app/services";

export function* fetchAutoSuggestListForStaffNameWatcher() {
    yield takeLatest(fetchAutoSuggestListForStaffNameTypename, fetchAutoSuggestListForStaffNameWorker);
  }
  
export function* fetchAutoSuggestListForStaffNameWorker(action: any): any {
    const {searchText, isActive} = action.payload;
    try {
        const url = API_PATH.inventoryModule.legderReportsAutoSuggestForStaff + `?SearchValue=${searchText}&IsActive=${isActive}`;
        const result = yield call(NetworkService.post, url, null, null);
        if (result.status === HTTP_OK) {
            yield put(inventoryModuleLedgerActions.setAutoSuggestListofStaff(result?.data));
        }
    } catch (error) {
        console.log(error);
        
    }
}