import Typography from '@mui/material/Typography';
import {useSelector, useDispatch} from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  TextFieldProps,
  Stack,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {SearchBarInventoryModule} from '../../search-bar-inventory-module';
import {intransistChallanActions} from '@app/screens/inventory-management/pages/intransit-challan/redux';
import {intransistChallanSelector} from '@app/screens/inventory-management/pages/intransit-challan/redux/selectors';
import {
  challanAuditDataCreator,
  fetchIntransistChallanCreator,
  inTransitFilteredData,
  intransitChallanDivisionCreator,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/slice';
import {inventoryModuleCnfActions} from '@app/screens/inventory-management/pages/cnf-return-items/redux';
import {useState, useEffect, useRef} from 'react';
import {ToogleSeperator} from '../../widgets/toogleSeperator';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import ChallanTableModal from '@app/screens/inventory-management/pages/intransit-challan/challanTableModal';
import MultiSelectDropdown from '@app/screens/inventory-management/pages/cnf-return-items/multiSelectionChips';
import dayjs from 'dayjs';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
  loaderStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
};

export const IntransitChallanNavBar = () => {
  const isOpenChallans = useSelector(
    intransistChallanSelector.getIsOpenChallans(),
  );
  const autoSuggestionData = useSelector(
    intransistChallanSelector.getAutoSuggestionData(),
  );
  const searchType = useSelector(intransistChallanSelector.getSearchType());
  const searchValue = useSelector(intransistChallanSelector.getSearchValue());
  const pageNumber = useSelector(intransistChallanSelector.getPage());

  const searchTypeLabel = {
    divisionname: 'Division Name',
    staffname: 'Name',
    challanNumber: 'Challan Number',
    staffCode: 'Staff Code',
    state: 'State Name',
  };

  const Search = () => {
    let searchdata = '';
    if (searchType === searchTypeLabel.staffname) {
      searchdata = 'staffname';
    } else if (searchType === searchTypeLabel.staffCode) {
      searchdata = 'staffCode';
    } else if (searchType === searchTypeLabel.challanNumber) {
      searchdata = 'challanNumber';
    } else if (searchType === searchTypeLabel.state) {
      searchdata = 'state';
    }
    return searchdata;
  };
  const dispatch = useDispatch();
  const handleToggleChange = () => {
    const data = Search();
    dispatch(
      inTransitFilteredData({searchType: data, searchValue: searchValue}),
    );
    dispatch(intransistChallanActions.setIsOpenChallans());
    dispatch(fetchIntransistChallanCreator({isOpenClose: !isOpenChallans}));
  };
  useEffect(() => {
    if (!isOpenChallans) {
      dispatch(intransistChallanActions.setIsOpenChallans());
    }
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfHeadquarter(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfReturnItems([]));
    return () => {
      dispatch(
        intransistChallanActions.setDivisionData({
          divisionData: [],
          divisionLoading: false,
          divisionError: '',
        }),
      );
    };
  }, []);

  //   autocomplete
  const [selectedItems, setSelectedItems] = useState<
    Array<{label: string; value: string}>
  >([]);

  //   Generate Report
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    dispatch(intransitChallanDivisionCreator());
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedItems([]);
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
  };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  /**
   * Handles the change of the start date.
   *
   * @param {Date | null} newDate - The new selected date.
   * @return {void} This function does not return anything.
   */
  const handleStartDateChange = (newDate: Date | null) => {
    if (newDate) {
      // Convert the selected start date to UTC
      const utcStartDate = new Date(
        Date.UTC(
          newDate.getUTCFullYear(),
          newDate.getUTCMonth(),
          newDate.getUTCDate(),
        ),
      );
      setStartDate(utcStartDate);
      setEndDate(null);
    }
  };
  /**
   * Handles the change event of the end date.
   *
   * @param {Date | null} newDate - The new selected end date.
   */
  const handleEndDateChange = (newDate: Date | null) => {
    if (newDate) {
      // Convert the selected end date to UTC
      const utcEndDate = new Date(
        Date.UTC(
          newDate.getUTCFullYear(),
          newDate.getUTCMonth(),
          newDate.getUTCDate(),
        ),
      );
      setEndDate(utcEndDate);
    }
  };

  const divisionData = useSelector(
    intransistChallanSelector.getIntransitChallanDivisionData(),
  );
  const diviosnLoader = useSelector(
    intransistChallanSelector.getIntransitChallanDivisionLoader(),
  );

  //   report view
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false);

  const handleAuditApi = () => {
    const postBody = {
      startDate: startDate,
      endDate: endDate,
      divisionIds: selectedItems.map(item => item.value),
      pageNumber: pageNumber,
    };
    dispatch(intransistChallanActions.setChallanReceivePayload(postBody));
    dispatch(challanAuditDataCreator(postBody));
  };
  useEffect(() => {
    if (startDate && endDate && selectedItems.length > 0) {
      handleAuditApi();
    }
  }, [pageNumber]);
  const handleReportOpen = () => {
    setOpenReport(true);
    handleAuditApi();
  };
  const handleReportClose = () => {
    setOpenReport(false);
    dispatch(intransistChallanActions.setChallanReceivesearchData([]));
  };
  const reportViewModal = () => {
    return (
      <Dialog
        open={openReport}
        fullWidth
        maxWidth="xl"
        sx={{
          '& .MuiDialog-paper': {
            width: 1380, // Set your desired width
            height: 750, // Set your desired height
          },
        }}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleReportClose}
            aria-label="close"
            sx={{position: 'absolute', right: 8, top: 8, marginRight: 0}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{overflow: 'hidden'}}>
          <Stack spacing={0}>
            <ChallanTableModal />
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  const checkValidation = () => {
    if (startDate !== null && endDate !== null && selectedItems?.length > 0) {
      return false;
    }
    return true;
  };
  const handleSelectionChange = (
    values: Array<{label: string; value: string}>,
  ) => {
    setSelectedItems(values);
    setOpenAutoComplete(false);
  };
  const addDays = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };

  const reportModal = () => {
    return (
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle marginTop={1}>
          <Typography color={'#322b7c'} fontSize={20}>
            View Report
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              marginRight: 0,
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={0}>
            {diviosnLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Box paddingBottom={2}>
                <Box flexDirection={'row'} display={'flex'}>
                  <Typography color={'#000000de'} fontWeight={'normal'}>
                    Division
                  </Typography>
                  <Typography color={'#FE5B38'}>*</Typography>
                </Box>
                <MultiSelectDropdown
                  options={divisionData}
                  selectedValues={selectedItems}
                  onChange={handleSelectionChange}
                  open={openAutoComplete}
                  setOpen={setOpenAutoComplete}
                />
              </Box>
            )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              paddingTop={3}>
              <div>
                <span>
                  Start Date<span style={{color: 'red'}}>*</span>
                </span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="yyyy/MM/dd"
                    value={startDate}
                    onChange={handleStartDateChange}
                    maxDate={dayjs().startOf('day').toDate()}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        sx={{width: 390}}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <span>
                  End Date<span style={{color: 'red'}}>*</span>
                </span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="yyyy/MM/dd"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        sx={{
                          width: 438,
                          '& .MuiInput-root': {
                            position: 'relative', // Ensure relative positioning
                          },
                          '& .MuiPopover-paper': {
                            left: '0 !important',
                            right: 'auto !important',
                            marginLeft: '0 !important', // Adjust this value as needed
                          },
                        }}
                      />
                    )}
                    disabled={startDate === null}
                    minDate={startDate || undefined}
                    maxDate={startDate ? addDays(startDate, 90) : undefined}
                  />
                </LocalizationProvider>
              </div>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{paddingTop: 4, marginBottom: 2}}>
          <Button
            disabled={checkValidation()}
            onClick={() => {
              handleReportOpen();
            }}
            color="primary"
            variant="contained"
            sx={{
              marginRight: 42,
              width: 200,
              textTransform: 'none',
            }}>
            View Report
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
          marginLeft={1}
          paddingTop={1.5}>
          Challan Closing and Receiving
        </Typography>
        <Box sx={[styles.flexSpaceBetween]}>
          <ToogleSeperator
            containerStyle={{width: '262px', marginRight: '20px'}}
            name="group-1"
            callback={handleToggleChange}
            controlRef={useRef()}
            segments={[
              {
                label: 'Open Challans',
                value: 'Open Challans',
                ref: useRef(),
              },
              {
                label: 'Closed Challans',
                value: 'Closed Challans',
                ref: useRef(),
              },
            ]}
          />
          <Button
            variant="contained"
            size="small"
            onClick={handleOpen}
            sx={{
              height: 40,
              marginRight: 1,
              textTransform: 'none',
              borderRadius: '20px',
            }}>
            Audit Report
          </Button>
        </Box>
      </Box>

      <SearchBarInventoryModule
        autoSuggestionData={autoSuggestionData?.searchValue}
      />
      {open && reportModal()}
      {openReport && reportViewModal()}
    </Box>
  );
};
