export const LedgerReportstyles = {
  containerCNF: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    width: '100%',
    margin: '0px',
    backgroundColor: 'white',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },

  container2: {
    width: '98%',
    margin: '0px',
    backgroundColor: 'white',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  startDateStyle: {
    width: 260,
    background: '#FFF',
    borderRadius: '4px',
    // '.MuiOutlinedInput-input': {
    //   padding: '9px !important',
    // },
  },
  endDateStyle: {
    width: 260,
    background: '#FFF',
    borderRadius: '4px',
    marginRight: 1,
    marginLeft: 0.5,
    // '.MuiOutlinedInput-input': {
    //   padding: '9px !important',
    // },
  },
  paddingForMUIInput: {
    '.MuiOutlinedInput-input': {
      padding: '9px !important',
    },
  },
  itemTypeStyle: {
    width: 420,
  },
  textField: {
    border: 'transparent',
    background: '#FFF',
    borderRadius: '5px',
    width: '100%',
    padding: '0px',
    opacity: 0.6,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: 12,
    '.MuiAutocomplete-input': {
      padding: '0px !important',
    },
  },
  textField2: {
    width: 420,
    '.MuiAutocomplete-input': {
      padding: '0px !important',
    },
  },
  padding5: {
    padding: '0px',
    background: '#FFF',
  },
  filterContainerCNF: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControlCNF: {
    margin: '8px',
  },
  iconCNF: {
    marginTop: '4px',
  },
  formContainer: {
    display: 'flex',
    // justifyContent: 'space-around',
    // marginLeft: 1,
    marginTop: '10px',
  },
  formControl: {
    // margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    borderRadius: '4px',
  },
  marginForSearch: {marginLeft: 10},
};
